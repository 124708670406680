import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import FooterList from "./FooterList.jsx";
import FooterForm from "./FooterForm.jsx";
import FooterPartners from "./FooterPartners.jsx";

const FooterContainer = (props) => {
    return (

        <footer className="p-t-75 p-b-32">
            <div className="call">
                <div className="container">
                    {/*<div className="footer-row">*/}
                    {/*    <div className="footer-row-item">*/}
                    {/*        <div className="row">*/}
                    {/*            <FooterList*/}
                    {/*                products={props.products}*/}
                    {/*            />*/}

                    {/*            <FooterForm />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}


                    <div className="call-row">
                        <div className="call-row-item">
                            <p>
                                Поговорити за{'\n'}життя з директором
                            </p>
                            <span><a href="tel:+380672169889">+380 67 216 98 89</a></span>
                            <span>Святослав Cергійович</span>
                        </div>
                        <div className="call-row-item">
                            <p>
                                Замовити багато папіру та {'\n'}отримати розклад по таро
                            </p>
                            <span>
                                <a href="tel:+380632218821">+380 63 221 88 21</a>
                            </span>
                            <span>Олена</span>
                        </div>
                    </div>
                    <div className="call-row-img">
                        <img src="/public/assets/images/cat.png" alt="Footer image"/>
                    </div>
                    <div className="call-row">
                        <div className="call-row-item">
                            <p>
                                ПРОСТО {'\n'}ЗАМОВИТИ {'\n'}ПАПІР <img src="/public/assets/images/logo.svg" alt="Footer logo"/>
                            </p>
                        </div>
                        <div className="call-row-item">
                            <p>
                                <a href="tel:+380444064000">
                                    +380 44 406 40 00
                                </a>
                            </p>
                            <p>
                                <a href="tel:+380934263647">
                                    +380 93 426 36 47
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
                <div className="container">
                    <FooterPartners/>
                </div>
        </footer>
    )
}

let mapStateToProps = (state) => {
    return {
        products: state.basket.products
    }
}
export default compose(
    connect(mapStateToProps)
)(FooterContainer)