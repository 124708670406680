import React, {useEffect, useState} from "react";
import HeaderNav from "./HeaderNav.jsx";
import HeaderMobileNav from "./HeaderMobileNav.jsx";
import {compose} from "redux";
import {connect} from "react-redux";
import Basket from "./Basket.jsx";
import {removeItem, totalPrice} from "../../../../products/redux/reducer.js";
import {getAllCategories} from "../redux/reducer.js";

const HeaderContainer = (props) => {
    const [fix, setFix] = useState(false);
    const [active, setActive] = useState(false);
    const [card, setCard] = useState(false);

    useEffect(() => {
        props.totalPrice()
    }, [props.basket]);

    useEffect(() => {
        props.getAllCategories();
    }, []);

    function setFixed() {
        if(window.scrollY >=70) {
            setFix(true);
        }else {
            setFix(false);
        }
    }

    window.addEventListener("scroll", setFixed);

    return(
        <>
            <header>
                <HeaderNav
                    basket={props.basket}
                    category={props.category}
                    fix={fix}
                    setCard={setCard}
                    card={card}
                    basketTotalQuantity={props.basketTotalQuantity}
                />

                <HeaderMobileNav
                    setActive={setActive}
                    active={active}
                    basket={props.basket}
                    category={props.category}
                    basketTotalQuantity={props.basketTotalQuantity}
                    setCard={setCard}
                />
            </header>

            <Basket
                basket={props.basket}
                setCard={setCard}
                card={card}
                basketTotalAmount={props.basketTotalAmount}
                removeItem={props.removeItem}
            />
        </>
    )
}


let mapStateToProps = (state) => {
    return {
        category: state.category.category,
        basket: state.basket.basket,
        basketTotalQuantity: state.basket.basketTotalQuantity,
        basketTotalAmount: state.basket.basketTotalAmount
    }
}
export default compose(
    connect(mapStateToProps, {
        totalPrice,
        removeItem,
        getAllCategories
    })
)(HeaderContainer)