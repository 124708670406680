import React from "react";
import {IoMdClose} from "react-icons/io";
import {NavLink} from "react-router-dom";

const Basket = (props) => {
    return(
        <div className={!props.card ? "wrap-header-cart js-panel-cart" : "wrap-header-cart js-panel-cart show-header-cart"}>
            <div className="s-full js-hide-cart" onClick={() => props.setCard(!props.card)}></div>

            <div className="header-cart flex-col-l p-l-65 p-r-25">
                <div className="header-cart-title flex-w flex-sb-m p-b-8">
					<span className="mtext-103 cl2">
						Твоя корзина
					</span>

                    <div className="fs-35 lh-10 cl2 p-lr-5 pointer hov-cl1 trans-04 js-hide-cart" onClick={() => props.setCard(!props.card)}>
                        <IoMdClose />
                    </div>
                </div>

                <div className="header-cart-content flex-w js-pscroll">
                    <ul className="header-cart-wrapitem w-full">

                        {
                            props.basket.map((product, i) => {
                                return (
                                    <li key={i} className="header-cart-item flex-w flex-t m-b-12">
                                        <div className="header-cart-item-img" onClick={() => props.removeItem(product)}>
                                            <img src={product.images[0]} alt="IMG" />
                                        </div>

                                        <div className="header-cart-item-txt p-t-8">
                                            <p className="header-cart-item-name m-b-18 hov-cl1 trans-04">
                                                {product.name}
                                            </p>

                                            <p className="header-cart-item-name m-b-18 hov-cl1 trans-04">
                                                Формат - {product.format.format}
                                            </p>

                                            <span className="header-cart-item-info">
                                                <p><span>{product.count} x</span>{parseFloat(product.price).toFixed(2)} <span>грн.</span> </p>
                                            </span>
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>

                    <div className="w-full">
                        <div className="header-cart-total w-full p-tb-40">
                            Всього: {props.basketTotalAmount} грн.
                        </div>

                        <div className="header-cart-buttons flex-w w-full">
                            <NavLink to="/checkout" onClick={() => props.setCard(!props.card)} className="flex-c-m stext-101 cl0 size-107 bg3 bor2 hov-btn3 p-lr-15 trans-04 m-b-10">Замовити</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Basket;