import React from "react";
import {Field, Form, Formik} from "formik";
import {FaMinus, FaPlus} from "react-icons/fa";
import PageForRedirect from "./PageForRedirect.jsx";
import {NavLink} from "react-router-dom";
import {validateEmail, validateNumber} from "../../utils/validation.js";

const BasketForm = (props) => {

    const formattedData = props.basket.reduce((acc, item) => {
        const existingProductIndex = acc.findIndex(p => p.phone === item.phone && p.email === item.email);
        if (existingProductIndex !== -1) {
            const existingProduct = acc[existingProductIndex];
            const existingFormatIndex = existingProduct.format.findIndex(f => f.id === item.id);
            if (existingFormatIndex !== -1) {
                existingProduct.format[existingFormatIndex].variant.push({
                    count: item.count,
                    variant_id: item.variant_id
                });
            } else {
                existingProduct.format.push({
                    id: item.id,
                    variant: [{
                        count: item.count,
                        variant_id: item.variant_id
                    }]
                });
            }
        } else {
            acc.push({
                phone: item.phone,
                email: item.email,
                format: [{
                    id: item.id,
                    variant: [{
                        count: item.count,
                        variant_id: item.variant_id
                    }]
                }]
            });
        }
        return acc;
    }, []);

    return(
        <>
            {
                !props.basket.length ?
                    <PageForRedirect /> :
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            phone: '',
                            email: '',
                        }}
                        onSubmit={
                            (values,{resetForm}) => {

                                const formData = {
                                    "products": formattedData,
                                };

                                formData.products.forEach(product => {
                                    product.phone = values.phone;
                                    product.email = values.email;
                                });

                                props.setOrder(formData);
                                resetForm({values: ''});
                            }}
                    >
                        {({errors, touched, isValidating, values }) => (
                            <Form>
                                <div className="bg0 p-t-75 p-b-85">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-10 col-xl-7 m-lr-auto m-b-50">
                                                <div className="m-l-25 m-r--38 m-lr-0-xl">
                                                    <div className="wrap-table-shopping-cart">
                                                        <table className="table-shopping-cart">
                                                            <thead>
                                                            <tr className="table_head">
                                                                <th className="column-1">Продукт</th>
                                                                <th className="column-2"></th>
                                                                <th className="column-3">Ціна</th>
                                                                <th className="column-4">Кількість</th>
                                                                <th className="column-5"></th>
                                                            </tr>
                                                            </thead>

                                                            <tbody>
                                                            {
                                                                props.basket.map((product, i) => {
                                                                    return(
                                                                        <tr key={i} className="table_row">
                                                                            <td className="column-1">
                                                                                <div className="how-itemcart1" onClick={() => props.removeItem(product)}>
                                                                                    <img src={product.images[0]}alt="IMG" />
                                                                                </div>
                                                                            </td>
                                                                            <td className="column-2">
                                                                                <NavLink to={`/products/${product.id}`}>
                                                                                    <p>{product.name}</p>
                                                                                </NavLink>
                                                                                <p className="variant-name">Варіант продукта - {product.format.format}</p>
                                                                                <span className="remove-btn" onClick={() => props.removeItem(product)}>Видалити</span>
                                                                            </td>
                                                                            <td className="column-3">
                                                                                <p>&#8372; {parseFloat(product.price).toFixed(2)}</p>
                                                                            </td>
                                                                            <td className="column-4">
                                                                                <div className="wrap-num-product flex-w m-l-auto m-r-0">
                                                                                    <div onClick={() => props.decrement(product)} className="btn-num-product-count btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m">
                                                                                        <FaMinus />
                                                                                    </div>

                                                                                    <Field type="number" disabled className="mtext-104 cl3 txt-center num-product" name="num-product1" value={product.count} />

                                                                                    <div onClick={() => props.addCount(product, product.variant_id)} className="btn-num-product-count btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m">
                                                                                        <FaPlus />
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="column-5">
                                                                                <p>{product.price * product.count} грн.</p>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-10 col-lg-7 col-xl-5 m-lr-auto m-b-50">
                                                <div className="bor10 p-lr-40 p-t-30 p-b-40 m-l-63 m-r-40 m-lr-0-xl p-lr-15-sm">
                                                    <h4 className="mtext-109 cl2 p-b-30">
                                                        ЗАГАЛЬНА СУМА
                                                    </h4>

                                                    <div className="flex-w flex-t bor12 p-t-15 p-b-30">
                                                        <div className="size-209 p-r-18 p-r-0-sm w-full-ssm pb-2">
                                                            <p className="stext-111 cl6 p-t-2">
                                                                Для замовлення заповніть форму або зв’яжіться з нами, якщо вам потрібна допомога.
                                                            </p>
                                                        </div>

                                                        <div className="form-group w-100">
                                                            <label htmlFor="exampleInputEmail1">Адреса електронної пошти</label>
                                                            <Field
                                                                type="email"
                                                                name="email"
                                                                className={errors.email ? "form-control error" : "form-control"}
                                                                aria-describedby="emailHelp"
                                                                validate={validateEmail}
                                                            />
                                                            <div className="errors">{errors.email}</div>
                                                        </div>

                                                        <div className="form-group w-100">
                                                            <label htmlFor="exampleInputEmail1">Номер телефону</label>
                                                            <Field
                                                                type="text"
                                                                name="phone"
                                                                className={errors.phone ? "form-control error" : "form-control"}
                                                                validate={validateNumber}
                                                                aria-describedby="emailHelp"
                                                            />
                                                            <div className="errors">{errors.phone}</div>
                                                        </div>
                                                    </div>

                                                    <div className="flex-w flex-t p-t-27 p-b-33">
                                                        <div className="size-208">
                                                <span className="mtext-101 cl2">
                                                    Всього:
                                                </span>
                                                        </div>

                                                        <div className="size-209 p-t-1">
                                                <span className="mtext-110 cl2">
                                                    {props.basketTotalAmount} грн.
                                                </span>
                                                        </div>
                                                    </div>

                                                    <button className="flex-c-m stext-101 cl0 size-116 bg3 bor14 hov-btn3 p-lr-15 trans-04 pointer">
                                                        Оформити замовлення
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
            }
        </>

    )
}
export default BasketForm;