import axios from "axios";
import bcrypt from 'bcryptjs';
import {toast} from "react-toastify";

const INSTANCE = axios.create({
    baseURL: import.meta.env.VITE_API_BASE_URL,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
});

async function hashKey() {
    const keyToHash = 'T4jEY+q//FOi6Z0p4xRsJquK4ngoWI01poiHpJrVrXIudD';
    const hash = await bcrypt.hash(keyToHash, 10);
    return hash;
}

const errorFunction = (error) => {
    toast.error(`Щось пішло не так. Повторіть спробу пізніше`, {
        position: "bottom-left"
    });
}

export const products = {

    async getAllProduct() {
        try {
            const hash = await hashKey();
            const response = await INSTANCE.post('/product-react/all', {
                key: hash
            });
            return response.data;
        } catch (error) {
            errorFunction(error);
        }
    },

    async getCategoryProducts(id) {
        try {
            const hash = await hashKey();
            const response = await INSTANCE.post('/product-react/cat', {
                id: id,
                key: hash
            });
            return response.data;
        } catch (error) {
            errorFunction(error);
        }
    },

    async getCurrentProduct(id) {
        try {
            const hash = await hashKey();
            const response = await INSTANCE.post('/product-react/one', {
                id: id,
                key: hash
            });
            return response.data;
        } catch (error) {
            errorFunction(error);
        }
    },

    async setOrderProducts(data) {
        try {
            const hash = await hashKey();
            const response = await axios.post('https://www.target.net.ua/dashboard/order/add',
                {
                    key: hash,
                    data
                },
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
            );
            toast.success(`Замовлення успішно відправлено!`, {
                position: "bottom-left"
            });
            return response.data;
        } catch (error) {
            errorFunction(error);
        }
    }
}

export const categories = {
    async getCategories() {
        try {
            const hash = await hashKey();
            const response = await INSTANCE.post('/category-react/all', {
                key: hash
            });
            return response.data;
        } catch (error) {
            errorFunction(error);
        }
    }
}