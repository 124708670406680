import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    fetching: false
};

const LoadingSlice = createSlice({
    name: "loading",
    initialState: initialState,
    reducers: {
        start(state) {
           state.fetching = true;
        },
        stop(state) {
            state.fetching = false;
        }
    }
})

export default LoadingSlice.reducer;
export const {
    start,
    stop
} = LoadingSlice.actions;

export const startFetching = () => dispatch =>  {
    dispatch(start());
}

export const stopFetching = () => (dispatch) => {
    dispatch(stop())
}