import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './componets/App.jsx';
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import {store} from "./redux/store/dev.js";
import {totalPrice} from "./componets/products/redux/reducer.js";

store.dispatch(totalPrice());

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <App />
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
)
