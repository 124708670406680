import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const HelmetContainer = ({title, description, keywords}) => {
    return(
        <HelmetProvider>
            <Helmet>
                <title>{title}</title>
                <meta name='description' content={description} />
                <meta name='keywords' content={keywords} />
            </Helmet>
        </HelmetProvider>
    )
}

export default HelmetContainer;