import React, {useEffect} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {addCount, allProducts} from "../redux/reducer.js";
import ProductList from "./ProductList.jsx";

const ProductsContainer = (props) =>{
    useEffect(() => {
        // props.allProducts();
    }, []);

    return(
        <>
            <ProductList
                products={props.products}
                addCount={props.addCount}
            />
        </>
    )
}

let mapStateToProps = (state) => {
    return {
        products: state.basket.products,
    }
}
export default compose(
    connect(mapStateToProps, {
        addCount,
        allProducts
    })
)(ProductsContainer)