import React from "react";
import {Routes, Route} from "react-router-dom";
import MainContainer from "./main/components/MainContainer.jsx";
import ProductsContainer from "./products/components/ProductsContainer.jsx";
import BasketContainer from "./basket/BasketContainer.jsx";
import ProductCurrentContainer from "./products/components/ProductCurrentContainer.jsx";
import ProductCategoryContainer from "./products/components/ProductCategoryContainer.jsx";

const RoutesComponent = () => {
    return(
        <Routes>
            <Route path="/" element={<MainContainer />} />
            <Route exact path="/products" element={<ProductsContainer />} />
            <Route path="/products/:id" element={<ProductCurrentContainer />} />
            <Route path="/category/:id" element={<ProductCategoryContainer />} />
            <Route path="/checkout" element={<BasketContainer />} />
        </Routes>
    )
}

export default RoutesComponent;