import {createSlice} from "@reduxjs/toolkit";
import {startFetching, stopFetching} from "../../../../settings/redux.js";
import {categories} from "../../../../api/api.js";

const initialState = {
    category: []
};

const categorySlice = createSlice({
    name: "category",
    initialState: initialState,
    reducers: {
        addCategoryToSate(state, action) {
            state.category = action.payload;
        }
    }
});

export default categorySlice.reducer;
export const {
    addCategoryToSate
} = categorySlice.actions;

export const getAllCategories = () => async dispatch => {

    dispatch(startFetching());

    const response = await categories.getCategories();
    dispatch(addCategoryToSate(response.categories));

    dispatch(stopFetching());

}

