import React from "react";
import {connect} from "react-redux";
import LoadingGeneric from "../generic/Loading.jsx";

const Loading = ({fetching, children}) => {

    return (
        <>
            {fetching && <LoadingGeneric />}
            {children}
        </>
    );

};

export default connect(state => ({
    fetching: state.settings.fetching
}))(Loading);
