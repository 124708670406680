import React, {useEffect, useState} from "react";
import {NavLink, useParams} from "react-router-dom";
import { Formik, Field, Form } from 'formik';
import HelmetContainer from "../../../utils/HelmetContainer.jsx";

const ProductCategory = (props) => {
    const [selectedFormat, setSelectedFormat] = useState({ title: "", description: "", keywords: "" });

    const handleFormatChange = (title, description, keywords) => {
        setSelectedFormat({title, description, keywords });
    };

    return(
        <>
            <HelmetContainer
                title={selectedFormat.title}
                description={selectedFormat.description}
                keywords={selectedFormat.keywords}
            />
            <section className="bg0 p-t-23 p-b-140 category-page">
                <div className="container">
                    <div className="p-b-10 category-page-title">
                        <h3 className="ltext-103 cl5">
                            {
                                props.category && props.category[0] ? (
                                    props.category[0].name
                                ) : (
                                    <div>Loading...</div>
                                )
                            }
                        </h3>
                        <p>
                            {
                                props.category && props.category[0] ? (
                                    props.category[0].description
                                ) : (
                                    <div>Loading...</div>
                                )
                            }
                        </p>
                    </div>

                    <div className="row isotope-grid category-page-row">
                        {
                            props.products.map((product) => {
                                return(
                                    <div key={product.id} className="col-sm-6 col-md-6 col-lg-4 isotope-item category-page-row-item">
                                        <div className="block2 category-page-row-item-box">
                                            <div className="block2-pic hov-img0">
                                                <img src={product.images_card} alt={product.title} />
                                                {/*<img src="https://images-na.ssl-images-amazon.com/images/I/712+KUJrijL._AC_UL600_SR600,600_.jpg" alt={product.title} />*/}

                                                <div className="block2-txt flex-w flex-t p-t-35">
                                                    <div className="block2-txt-child1 flex-col-l category-page-name">
                                                        <p>
                                                            {product.name}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="block2-txt flex-w flex-t p-t-35">
                                                    <div className="block2-txt-child1 flex-col-l category-page-format-desc">
                                                        <p>
                                                            {product.format[0].exception}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="block2-txt flex-w flex-t p-t-35">
                                                    <div className="block2-txt-child1 flex-col-l category-page-link">
                                                        <NavLink to={`/products/${product.id}`} className="stext-104 cl4 hov-cl1 trans-04 js-name-b2 p-b-6">
                                                            <span>докладніше</span>
                                                            <div className="image-container">
                                                                <img src="/public/assets/images/arr2.svg" alt="Main Image" width="50" height="50" />
                                                                <img src="/public/assets/images/arr1.svg" className="hover-image" alt="Hover Image" width="50" height="50" />
                                                            </div>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                    {
                        props.category && props.category[0] ? (
                            <div dangerouslySetInnerHTML={{__html: props.category[0].content}}/>
                        ) : (
                            <div>Loading...</div>
                        )
                    }

                </div>
            </section>
        </>
    )
}

export default ProductCategory;