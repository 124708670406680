import {combineReducers} from "@reduxjs/toolkit";
import basketReducer from "../componets/products/redux/reducer.js";
import settingsReducer from "../componets/settings/redux";
import categoryReducer from "../componets/layouts/componets/header/redux/reducer.js";


const rootReducer = combineReducers({
    basket: basketReducer,
    category: categoryReducer,
    settings: settingsReducer,
});

export default rootReducer;