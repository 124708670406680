import React from "react";
import {NavLink} from "react-router-dom";


const ProductList = (props) => {

    return(
        <section className="bg0 p-t-23 p-b-140">
            <div className="container">
                <div className="p-b-10">
                    <h3 className="ltext-103 cl5">
                        Product Overview
                    </h3>
                </div>

                <div className="row isotope-grid">
                    {
                        props.products.map((product) => {
                            return(
                                <div key={product.id} className="col-sm-6 col-md-4 col-lg-3 p-b-35 isotope-item">
                                    <div className="block2">
                                        <div className="block2-pic hov-img0">
                                            <img src={product.images[0]} alt={product.title} />

                                            <div onClick={() => {props.addCount(product, "a3")}} className="block2-btn flex-c-m stext-103 cl2 size-102 bg0 bor2 hov-btn1 p-lr-15 trans-04 js-show-modal1">
                                                Купити
                                            </div>
                                        </div>

                                        <div className="block2-txt flex-w flex-t p-t-14">
                                            <div className="block2-txt-child1 flex-col-l ">
                                                <NavLink to={`/products/${product.id}`}
                                                       className="stext-104 cl4 hov-cl1 trans-04 js-name-b2 p-b-6">
                                                    {product.name}
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="flex-c-m flex-w w-full p-t-45">
                    <a href="#" className="flex-c-m stext-101 cl5 size-103 bg2 bor1 hov-btn1 p-lr-15 trans-04">
                        Load More
                    </a>
                </div>
            </div>
        </section>
    )
}
export default ProductList;