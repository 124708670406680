import React from 'react';
import RoutesComponent from "../../../routes.jsx";


const Main = () => {
    return (
        <div className="inner-page">
            <RoutesComponent/>
        </div>
    );
}

export default Main;