import React from "react";
import {NavLink} from "react-router-dom";

const PageForRedirect = () => {
    return(
        <section className="bg0 p-t-75 p-b-120">
            <div className="container">
                <div className="row p-b-148">
                    <div className="col-md-12">
                        <div className="p-t-7 p-r-85 p-r-15-lg p-r-0-md">
                            <h3 className="mtext-111 cl2 p-b-16">
                                Корзина порожня
                            </h3>
                            <NavLink to="/" className="flex-c-m stext-101 cl5 size-103 bg2 bor1 hov-btn1 p-lr-15 trans-04 w-25">Повернутися до списку товарів</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </section>
)
}
export default PageForRedirect;