import React, {useEffect} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import BasketForm from "./BasketForm.jsx";
import {addCount, decrement, removeItem, setOrder, totalPrice} from "../products/redux/reducer.js";

const BasketContainer = (props) => {
    useEffect(() => {
        props.totalPrice()
    }, [props.basket]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        })
    }, [props])

    return(
       <BasketForm
           basket={props.basket}
           addCount={props.addCount}
           removeItem={props.removeItem}
           decrement={props.decrement}
           basketTotalAmount={props.basketTotalAmount}
           setOrder={props.setOrder}
       />
    )
}

let mapStateToProps = (state) => {
    return {
        basket: state.basket.basket,
        basketTotalAmount: state.basket.basketTotalAmount
    }
}

export default compose(
    connect(mapStateToProps, {
        addCount,
        removeItem,
        decrement,
        totalPrice,
        setOrder
    })
)(BasketContainer);