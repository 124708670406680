import React, {useEffect} from 'react';
import '../../style/index.sass';
import Content from "./layouts/componets/content/Content.jsx";
import HeaderContainer from "./layouts/componets/header/components/HeaderContainer.jsx";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import Loading from "./layouts/componets/loading/Loading.jsx";
import FooterContainer from "./layouts/componets/footer/components/FooterContainer.jsx";
import {compose} from "redux";
import {connect} from "react-redux";
import {allProducts} from "./products/redux/reducer.js";

function App(props) {

    useEffect(() => {
        props.allProducts();
    }, []);

  return (
    <div className="wrapper">
        <ToastContainer />
        <HeaderContainer />

        <main id="main">
            <Loading>
                <Content />
            </Loading>
        </main>

        <FooterContainer />
    </div>
  )
}

// export default App;

export default compose(
    connect(null, {
        allProducts
    })
)(App)
