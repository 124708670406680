import React, {useEffect, useState} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {addCount, getCategoryProduct} from "../redux/reducer.js";
import {useParams} from "react-router-dom";
import ProductCategory from "./ProductCategory.jsx";

const ProductCategoryContainer = (props) => {
    const { id } = useParams();
    const [categoryId, setId] = useState(id);

    const filteredData = props.category.filter((item) => {
        return item.id === parseInt(id)
    });

    useEffect(() => {
        if (!props.currentProduct || props.currentProduct.id !== Number(categoryId)) {
            props.getCategoryProduct(id);
        }
    }, [categoryId, id]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        })
    }, [props])

    return(
        <ProductCategory
            products={props.products}
            addCount={props.addCount}
            category={filteredData}
        />
    )

}

let mapStateToProps = (state) => {
    return {
        products: state.basket.categoryProducts,
        category: state.category.category
    }
}
export default compose(
    connect(mapStateToProps, {
        getCategoryProduct,
        addCount
    })
)(ProductCategoryContainer)