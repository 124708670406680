import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import {allProducts} from "../../products/redux/reducer.js";
import HelmetContainer from "../../../utils/HelmetContainer.jsx";
import {NavLink} from "react-router-dom";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const MainContainer = (props) => {

    const [isVisible, setIsVisible] = useState(false);
    const { ref, inView } = useInView({
        triggerOnce: true,
    });

    useEffect(() => {
        if (inView) {
            setIsVisible(true);
        }
    }, [inView]);

    const variants = {
        visible: { x: 0, opacity: 1 },
        hidden: { x: -100, opacity: 0 },
    };
    const variants2 = {
        visible: { x: 0, opacity: 1 },
        hidden: { x: 100, opacity: 0 },
    };

    // useEffect(() => {
    //     props.allProducts();
    // }, []);

    const settings = {
            pauseOnFocus: false,
            pauseOnHover: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            speed: 1000,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 6000,
            arrows: true,
    }
    return (
        <>
            <HelmetContainer
                title='Target Paper - High-quality paper. Sharp results.'
                description='The new Target office paper offers a complete range of high-quality solutions for everyday needs and demanding applications.'
                keywords='Main keywords'
            />
            <section className="target">
                <div className="container">
                    <div className="target-row">
                        <motion.div
                            whileHover={{ scale: 1.1 }} whileTap={{ scale: 1 }}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 1 }}
                            className="target-row-item"
                        >
                            <img src="/public/assets/images/all2.png" alt="Target products"/>
                        </motion.div>
                        <div className="target-row-item">
                            <ul className="target-row-item-list">
                                <motion.li
                                    initial={{ y: -1000, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    transition={{ duration: 2.5 }}
                                >
                                    <img src="/public/assets/images/1_blue2.svg" alt="Target icon"/>
                                    <p>Унікальне{'\n'}волокно</p>
                                </motion.li>
                                <motion.li
                                    initial={{ y: -1000, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    transition={{ duration: 2 }}
                                >
                                    <img src="/public/assets/images/2_blue2.svg" alt="Target icon"/>
                                    <p>Висока{'\n'}непрозорість</p>
                                </motion.li>
                                <motion.li
                                    initial={{ y: -1000, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    transition={{ duration: 1.5 }}
                                >
                                    <img src="/public/assets/images/3_blue2.svg" alt="Target icon"/>
                                    <p>Неперевершена{'\n'}білизна</p>
                                </motion.li>
                                <motion.li
                                    initial={{ y: -1000, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    transition={{ duration: 1 }}
                                >
                                    <img src="/public/assets/images/4_blue2.svg" alt="Target icon"/>
                                    <p>Гарантована{'\n'}гладкість</p>
                                </motion.li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <div className="target-link__bg">
                <NavLink to="/category/6" className="target-link">
                    <div className="container">
                        <p>
                            бачу ціль - {'\n'}купую
                            <img src="/public/assets/images/target_white.svg" alt=""/>
                        </p>
                    </div>
                </NavLink>
            </div>
            <section className="more">
                <div className="container">
                    <div className="more-row">
                        <div className="more-row-item more-row-item__left">
                            <h2>
                                БАЖАЄШ БІЛЬШОГО?
                            </h2>
                            <p>
                                Лінійка <img src="/public/assets/images/logo.svg" alt="Logo icon"/> для яскравих кольорових документів
                            </p>
                        </div>
                        <div className="more-row-item">
                            <img src="/public/assets/images/more.jpg" alt="All products images"/>
                            <motion.div
                                whileHover={{ scale: 1.05 }} whileTap={{ scale: 1 }}
                                transition={{ duration: 1 }}
                                className="more-btn"
                            >
                                <NavLink to="/category/8">
                                    Замовити
                                    <img src="/public/assets/images/target_blue_new.svg" alt="Orange arrow"/>
                                </NavLink>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default compose(
    connect(null, {
        allProducts
    })
)(MainContainer)