export const required = (value) => {
    let error;
    if (!value) {
        error = 'Required';
    }
    return error;
}

export const validateEmail = (value) => {
    let error;
    if (!value) {
        error = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        error = 'Invalid email address';
    }
    return error;
}

export const validateNumber = (value) => {
    /* eslint-disable no-useless-escape */
    let error;
    if (!value) {
        error = 'Required';
    } else if (!/(?:\+38)?(?:\(\d{3}\)|\d{3})[\s-]?\d{2}[\s-]?\d{2}[\s-]?\d{3}/.test(value)) {
        error = 'Invalid email phone number';
    }
    return error;
}


