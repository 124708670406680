import React from "react";
import {NavLink} from "react-router-dom";
import {motion, useScroll, useSpring} from "framer-motion";

const HeaderNav = (props) => {

    const { scrollYProgress } = useScroll();
    const scaleX = useSpring(scrollYProgress, {
        stiffness: 100,
        damping: 30,
        restDelta: 0.001
    });

    return(
        <div className={props.fix ? "container-menu-desktop fix-menu-desktop" : "container-menu-desktop"}>
            <motion.div className="progress-bar-line" style={{ scaleX }} />
            <div className="wrap-menu-desktop">
                <nav className="limiter-menu-desktop container">

                    <NavLink to="/" className="logo">
                        <img src="/public/assets/images/logo.svg" alt="IMG-LOGO" />
                    </NavLink>

                    <div className="menu-desktop">
                        <ul className="main-menu">

                            {/*<li>*/}
                            {/*    <NavLink to="/products" className="nav-link scrollto">*/}
                            {/*        Продукти*/}
                            {/*    </NavLink>*/}
                            {/*</li>*/}

                            {
                                props.category.map((item) => {
                                    return(
                                        <li key={item.id}>
                                            <NavLink to={`/category/${item.id}`} className="nav-link scrollto">
                                                {item.name}
                                            </NavLink>
                                        </li>
                                    )
                                })
                            }

                            {/*<li>*/}
                            {/*    <NavLink to="/test" className="nav-link scrollto">*/}
                            {/*        Для оптових покупців*/}
                            {/*    </NavLink>*/}
                            {/*</li>*/}
                        </ul>
                    </div>

                    <div className="wrap-icon-header flex-w flex-r-m">
                        <div className="icon-header-item cl2 hov-cl1 trans-04 p-l-22 p-r-11 icon-header-noti js-show-cart" onClick={() => props.setCard(!props.card)} data-notify={props.basketTotalQuantity}>
                            <img src="/public/assets/images/cart.svg" alt="cart-image"/>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default HeaderNav;