import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {connect} from "react-redux";
import {addCount, getCurrentProduct} from "../redux/reducer.js";
import {compose} from "redux";
import ProductCurrent from "./ProductCurrent.jsx";
// import 'animate.css';

const ProductCurrentContainer = (props) => {
    const { id } = useParams();

    useEffect(() => {
        if (!props.currentProduct || props.currentProduct.id !== Number(id)) {
            props.getCurrentProduct(id);
        }
    }, [id]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        })
    }, [props])

    return (
            <ProductCurrent
                currentProduct = {props.currentProduct}
                addCount={props.addCount}
            />
    );
}

let mapStateToProps = (state) => {
    return {
        currentProduct: state.basket.currentProduct
    }
}
export default compose(
    connect(mapStateToProps, {
        getCurrentProduct,
        addCount
    })
)(ProductCurrentContainer)