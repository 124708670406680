import {createSlice} from "@reduxjs/toolkit";
import {toast} from "react-toastify";
import {products} from "../../api/api.js";
import {startFetching, stopFetching} from "../../settings/redux.js";


const initialState = {
    products: [],
    categoryProducts: [],
    currentProduct: [],
    basket: localStorage.getItem("basket")
    ? JSON.parse(localStorage.getItem("basket"))
    : [],
    basketTotalQuantity: 0,
    basketTotalAmount: 0
};

const basketSlice = createSlice({
    name: "basket",
    initialState: initialState,
    reducers: {
        addProductsToState(state, action) {
            state.products = action.payload;
        },
        addProductsToCategoryState(state, action) {
            state.categoryProducts = action.payload;
        },
        cleanBasket(state) {
            state.basket = [];
        },
        addToBasket(state, action) {
            const existingProductIndex = state.basket.findIndex(item =>
                item.id === action.payload.product.id && item.variant_id === action.payload.variant_id
            );

            if (existingProductIndex !== -1) {
                state.basket[existingProductIndex].count++;
            } else {
                const variantId = action.payload.variant_id;
                const format = action.payload.product.format.find(item => item.variant_id === variantId);
                const price = action.payload.product.format.find(item => item.variant_id === action.payload.variant_id);
                if (format) {
                    const tempProduct = {
                        ...action.payload.product,
                        count: 1,
                        price: format.price,
                        variant_id: variantId,
                        format: format
                    };
                    state.basket.push(tempProduct);
                    toast.success(`${action.payload.product.name} доданий в корзину`, {
                        position: "bottom-left"
                    });
                }
            }

            localStorage.setItem("basket", JSON.stringify(state.basket));
        },
        removeFromBasket(state, action) {
            const { id, format} = action.payload;
            const itemIndex = state.basket.findIndex((basketItem) => {
                return(
                    basketItem.id === id && basketItem.variant_id === format.variant_id
                )
            });
            if (itemIndex !== -1) {
                state.basket.splice(itemIndex, 1);
                localStorage.setItem("basket", JSON.stringify(state.basket));
            }
        },
        decrease(state, action) {
            const { id, format } = action.payload;
            const itemIndex = state.basket.findIndex(basketItem =>
                basketItem.id === id && basketItem.variant_id === format.variant_id
            );

            if (itemIndex !== -1) {
                if (state.basket[itemIndex].count > 1) {
                    state.basket[itemIndex].count--;
                    localStorage.setItem("basket", JSON.stringify(state.basket));
                } else {
                    state.basket.splice(itemIndex, 1);
                    localStorage.setItem("basket", JSON.stringify(state.basket));
                }
            }
        },
        getTotals(state) {
            let { total, quantity } = state.basket.reduce((basketTotal, basketItem) => {
                const {price, count} = basketItem;
                const itemTotal = price * count;

                basketTotal.total += itemTotal;
                basketTotal.quantity += count;

                return basketTotal;
            }, {
                total: 0,
                quantity: 0
            });
            state.basketTotalQuantity = quantity;
            state.basketTotalAmount = total;
        },
        currentProduct(state, action){
            state.currentProduct = action.payload;
        }
    }
})

export default basketSlice.reducer;

export const {
    addToBasket,
    removeFromBasket,
    decrease,
    getTotals,
    currentProduct,
    addProductsToState,
    addProductsToCategoryState,
    cleanBasket
} = basketSlice.actions;

export const allProducts = () => async dispatch => {
    dispatch(startFetching());

    const response = await products.getAllProduct();
    dispatch(addProductsToState(response.products));

    dispatch(stopFetching());
}
export const getCategoryProduct = (id) =>  async dispatch => {
    dispatch(startFetching());

    const response = await products.getCategoryProducts(id);
    dispatch(addProductsToCategoryState(response.products));

    dispatch(stopFetching());
}
export const addCount = (product, variant_id) => (dispatch) =>  {
    dispatch(addToBasket({product, variant_id}));
}

export const removeItem = (product) => (dispatch) => {
    dispatch(removeFromBasket(product))
}

export const decrement = (product) => (dispatch) => {
    dispatch(decrease(product))
}

export const totalPrice = () => (dispatch) => {
    dispatch(getTotals())
}

export const getCurrentProduct = (id) => async dispatch => {

    dispatch(startFetching());

    const response = await products.getCurrentProduct(id);
    dispatch(currentProduct(response.products));

    dispatch(stopFetching());

}

export const setOrder = (data) => async dispatch => {
    dispatch(startFetching());

    const response = await products.setOrderProducts(data);
    dispatch(cleanBasket());

    dispatch(stopFetching());
}
