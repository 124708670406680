import React from "react";
import {NavLink} from "react-router-dom";

const HeaderMobileNav = (props) => {
    return (
        <>
            <div className="wrap-header-mobile">
                <div className="logo-mobile">
                    <NavLink to="/" className="logo">
                        <img src="/public/assets/images/logo.svg" alt="IMG-LOGO" />
                    </NavLink>
                </div>

                <div className="wrap-icon-header flex-w flex-r-m m-r-15">
                    <div className="icon-header-item cl2 hov-cl1 trans-04 p-r-11 p-l-10 icon-header-noti js-show-cart" onClick={() => props.setCard(!props.card)} data-notify={props.basketTotalQuantity}>
                        <img src="/public/assets/images/cart.svg" alt="cart-image"/>
                    </div>
                </div>

                <div onClick={
                    () => props.setActive(!props.active)}
                     className={props.active ?
                         "btn-show-menu-mobile hamburger hamburger--squeeze is-active" :
                         "btn-show-menu-mobile hamburger hamburger--squeeze"
                     }>
				<span className="hamburger-box">
					<span className="hamburger-inner"></span>
				</span>
                </div>
            </div>
            <div className="menu-mobile" style={{display: props.active ? 'block' : 'none'}}>
                <ul className="main-menu-m">

                    {/*<li>*/}
                    {/*    <NavLink to="/products" className="nav-link scrollto">*/}
                    {/*        Продукти*/}
                    {/*    </NavLink>*/}
                    {/*</li>*/}

                    {
                        props.category.map((item) => {
                            return(
                                <li key={item.id}>
                                    <NavLink to={`/category/${item.id}`} className="nav-link scrollto">
                                        {item.name}
                                    </NavLink>
                                </li>
                            )
                        })
                    }

                    {/*<li>*/}
                    {/*    <NavLink to="/test" className="nav-link scrollto">*/}
                    {/*        Для оптових покупців*/}
                    {/*    </NavLink>*/}
                    {/*</li>*/}

                </ul>
            </div>
        </>
    )
}

export default HeaderMobileNav;