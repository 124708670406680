import React from "react";

const FooterPartners = (props) => {
    return(
        <div className="p-t-40">
            <div className="row">
                <div className="col-lg-3">
                    <div className="footer-partners-desc">
                        <img src="/public/assets/images/the-navigator-company.svg" alt="Partners logo"/>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="footer-partners-desc">
                        <p className="txt-center description">
                            {new Date().getFullYear()} &copy; Усі права захищено
                        </p>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="footer-partners-desc partners-logo">
                        <img src="/public/assets/images/pefc.svg" alt="Partners logo"/>
                        <img src="/public/assets/images/fsc.svg" alt="Partners logo"/>
                        <img src="/public/assets/images/ecolabel.svg" alt="Partners logo"/>
                        <img src="/public/assets/images/bli.svg" alt="Partners logo"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FooterPartners;