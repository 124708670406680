import React, {useEffect, useRef, useState} from "react";
import {Formik, Field, Form} from 'formik';
import $ from 'jquery';
import HelmetContainer from "../../../utils/HelmetContainer.jsx";


const animateNumber = ({initialValue, finalValue, duration, selector}) => {
    const animateOptions = {
        duration: duration,
        step: function (now) {
            $(this).text(Math.ceil(now));
        }
    };
    $(selector).animate({
        num: finalValue
    }, animateOptions);
};

const ProductCurrent = ({currentProduct, addCount}) => {
    const [selectedFormat, setSelectedFormat] = useState({title: "", description: "", keywords: ""});
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedFormatArray, setSelectedFormatArray] = useState([]);

    //Add the format value to the current meta tags when changing the format (event onChange)
    const handleFormatChange = (title, description, keywords) => {
        setSelectedFormat(prevFormat => ({
            ...prevFormat,
            title: `${prevFormat.title.split(' - ')[0]} - ${title}`,
            description: `${prevFormat.description.split(' - ')[0]} - ${description}`,
            keywords: `${prevFormat.keywords.split(' - ')[0]} - ${keywords}`
        }));
    };

    //Default value (In Ukrainian)
    const [selectedRadio, setSelectedRadio] = useState("А4");

    //We set meta tags from the product that arrived through props
    useEffect(() => {
        if (currentProduct && currentProduct.length > 0) {
            setSelectedFormat({
                title: currentProduct[0].title,
                description: currentProduct[0].meta_description,
                keywords: ""
            });
        }
    }, [currentProduct]);

    //We filter the product. We look at what format value is the default and add to selectedFormatArray only the format that we have by default in selectedRadio
    useEffect(() => {
        if (currentProduct && currentProduct.length > 0) {
            const product = currentProduct.find(product => product.format.some(item => item.format === selectedRadio));
            setSelectedProduct(product);
            setSelectedFormatArray(product ? product.format.filter(item => item.format === selectedRadio) : []);
        }
    }, [currentProduct, selectedRadio]);

    //Animations of numbers
    useEffect(() => {
        animateNumber({
            initialValue: 0,
            finalValue: 163,
            duration: 3000,
            selector: '#number1'
        });

        animateNumber({
            initialValue: 0,
            finalValue: 93,
            duration: 4000,
            selector: '#number2'
        });
        animateNumber({
            initialValue: 0,
            finalValue: 180,
            duration: 4000,
            selector: '#number3'
        });
        animateNumber({
            initialValue: 0,
            finalValue: 107,
            duration: 3000,
            selector: '#number4'
        });
    }, [currentProduct]);

    return (
        <>
            <HelmetContainer
                title={selectedFormat.title}
                description={selectedFormat.description}
                keywords={selectedFormat.keywords}
            />

            <div className="current-product">
                <div className="product-choice">
                    <div className="container">
                            <Formik
                                initialValues={{
                                    format: "А4",
                                    variant_id: ""
                                }}
                                onSubmit={() => {}}
                            >
                                {({values, setFieldValue}) => (
                                    <Form>
                                        <div className="product-choice-row">
                                            {Array.isArray(currentProduct) && currentProduct.length > 0 && (
                                                currentProduct.map((product, index) => (
                                                    <React.Fragment key={index}>
                                                        {
                                                            selectedFormatArray.map((item, index) => {
                                                                return (
                                                                    <div className="product-choice-row-item"  key={index}>
                                                                        <div className="paper-logo">
                                                                            <p>{item.classification}</p>
                                                                        </div>
                                                                        <div className="paper-num">
                                                                            <p>{item.weight}</p>
                                                                            <p>г/см<sup>2</sup></p>
                                                                        </div>
                                                                        <div className="paper-quantity">
                                                                            <p>{item.cpage} аркушів</p>
                                                                        </div>
                                                                        <div className="paper-select">
                                                                            <div role="group"
                                                                                 aria-labelledby="my-radio-group">
                                                                                <div className="radio">
                                                                                    {
                                                                                        product.format.map((item, i) => {
                                                                                            return (
                                                                                                <label key={i}>
                                                                                                    <Field
                                                                                                        label={item.format}
                                                                                                        type="radio"
                                                                                                        name="format"
                                                                                                        value={item.format}
                                                                                                        checked={selectedRadio === item.format}
                                                                                                        onChange={() => {
                                                                                                            setSelectedRadio(item.format);
                                                                                                            setFieldValue("format", item.format);
                                                                                                            handleFormatChange(
                                                                                                                `${item.format}`,
                                                                                                                `${item.format}`,
                                                                                                                `${item.format}`
                                                                                                            );
                                                                                                        }}
                                                                                                    />
                                                                                                </label>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div className="paper-description">
                                                                            <p>{item.exception}</p>
                                                                        </div>
                                                                        <div className="paper-order">
                                                                            <div className="paper-order-price">
                                                                                <p className="old-price">{parseFloat(item.old_price).toFixed(2)}
                                                                                    <span> грн</span></p>
                                                                                <p>{parseFloat(item.price).toFixed(2)}
                                                                                    <span> грн</span></p>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            values.format ?
                                                                                <div onClick={() => {
                                                                                    addCount(product, selectedFormatArray[0].variant_id)
                                                                                }}  className={`btn-buy`}>
                                                                                    Замовити
                                                                                    <img
                                                                                        src="/public/assets/images/button-icon.svg"
                                                                                        alt="Button icon"/>
                                                                                </div> :
                                                                                <div className={`btn-buy disable`}>
                                                                                    Замовити
                                                                                    <img
                                                                                        src="/public/assets/images/button-icon.svg"
                                                                                        alt="Button icon"/>
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        <div className="product-choice-row-item">
                                                            <img src={product.images_card} alt="Main product img"/>
                                                        </div>
                                                    </React.Fragment>
                                                ))
                                            )}
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                    </div>
                </div>
                {
                    currentProduct && currentProduct[0] ? (
                        <div dangerouslySetInnerHTML={{__html: currentProduct[0].description}}/>
                    ) : (
                        <div>Loading...</div>
                    )
                }
            </div>
        </>
    );
}

export default ProductCurrent;
